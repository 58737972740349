/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";

type GLTFResult = GLTF & {
  nodes: {
    d20: THREE.Mesh;
    ["10"]: THREE.Mesh;
    ["11"]: THREE.Mesh;
    ["12"]: THREE.Mesh;
    ["13"]: THREE.Mesh;
    ["14"]: THREE.Mesh;
    ["15"]: THREE.Mesh;
    ["16"]: THREE.Mesh;
    ["17"]: THREE.Mesh;
    ["18"]: THREE.Mesh;
    ["19"]: THREE.Mesh;
    ["2"]: THREE.Mesh;
    ["3"]: THREE.Mesh;
    ["4"]: THREE.Mesh;
    ["5"]: THREE.Mesh;
    ["6"]: THREE.Mesh;
    ["7"]: THREE.Mesh;
    ["8"]: THREE.Mesh;
    ["9"]: THREE.Mesh;
    Border: THREE.Mesh;
    Border001: THREE.Mesh;
    Border002: THREE.Mesh;
    Border003: THREE.Mesh;
    Border004: THREE.Mesh;
    Border005: THREE.Mesh;
    Border006: THREE.Mesh;
    Border007: THREE.Mesh;
    Border008: THREE.Mesh;
    Border009: THREE.Mesh;
    Cerberus: THREE.Mesh;
    Lightning: THREE.Mesh;
  };
  materials: {
    ["Material.008"]: THREE.MeshStandardMaterial;
    ["Material.019"]: THREE.MeshStandardMaterial;
    ["Material.037"]: THREE.MeshStandardMaterial;
    ["Material.017"]: THREE.MeshStandardMaterial;
    ["Material.029"]: THREE.MeshStandardMaterial;
    ["Material.010"]: THREE.MeshStandardMaterial;
    ["Material.021"]: THREE.MeshStandardMaterial;
    ["Material.043"]: THREE.MeshStandardMaterial;
    ["Material.027"]: THREE.MeshStandardMaterial;
    ["Material.015"]: THREE.MeshStandardMaterial;
    ["Material.033"]: THREE.MeshStandardMaterial;
    ["Material.004"]: THREE.MeshStandardMaterial;
    ["Material.035"]: THREE.MeshStandardMaterial;
    ["Material.013"]: THREE.MeshStandardMaterial;
    ["Material.023"]: THREE.MeshStandardMaterial;
    ["Material.041"]: THREE.MeshStandardMaterial;
    ["Material.025"]: THREE.MeshStandardMaterial;
    ["Material.006"]: THREE.MeshStandardMaterial;
    ["Material.039"]: THREE.MeshStandardMaterial;
    ["SVGMat.002"]: THREE.MeshStandardMaterial;
    ["SVGMat.005"]: THREE.MeshStandardMaterial;
    ["SVGMat.003"]: THREE.MeshStandardMaterial;
  };
};

export default function Model(props: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>();
  const { nodes, materials } = useGLTF("/Greek Gold.glb") as GLTFResult;

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.d20.geometry}
        material={materials["Material.008"]}
        rotation={[0.68, 0, 0]}
        scale={[0.11, 0.11, 0.11]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["10"].geometry}
          material={materials["Material.019"]}
          position={[-3.72, -3.44, 9.29]}
          rotation={[0.6, 1.2, 1.34]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["11"].geometry}
          material={materials["Material.037"]}
          position={[3.85, 3.71, -9.14]}
          rotation={[-2.98, -1.2, -1.76]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["12"].geometry}
          material={materials["Material.017"]}
          position={[-6, 3.36, 8.05]}
          rotation={[-1.34, 0.71, 2.27]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["13"].geometry}
          material={materials["Material.029"]}
          position={[-3.71, 3.32, -9.34]}
          rotation={[1.76, -0.4, 2.74]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["14"].geometry}
          material={materials["Material.010"]}
          position={[9.88, 3.45, 1.56]}
          rotation={[-0.21, -0.2, -1.26]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["15"].geometry}
          material={materials["Material.021"]}
          position={[-9.87, 3.64, 1.16]}
          rotation={[1.96, -0.36, 1.58]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["16"].geometry}
          material={materials["Material.043"]}
          position={[6.19, -7.72, 3.76]}
          rotation={[-0.36, -0.1, -2.52]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["17"].geometry}
          material={materials["Material.027"]}
          position={[-6.08, -7.89, 3.57]}
          rotation={[-2.54, -0.88, 1.13]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["18"].geometry}
          material={materials["Material.015"]}
          position={[-0.37, 10.58, 0.04]}
          rotation={[-0.01, -0.01, 0]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["19"].geometry}
          material={materials["Material.033"]}
          position={[0.09, -7.73, -7.24]}
          rotation={[-2.42, -1, 0]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          name="2"
          castShadow
          receiveShadow
          geometry={nodes["2"].geometry}
          material={materials["Material.004"]}
          position={[-0.39, 7.72, 7.24]}
          rotation={[0.72, 1, 0]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3"].geometry}
          material={materials["Material.035"]}
          position={[-0.14, -10.58, 0.13]}
          rotation={[3.13, -0.14, 0]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["4"].geometry}
          material={materials["Material.013"]}
          position={[6.68, 7.75, -2.86]}
          rotation={[0.53, 0.86, -1.08]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5"].geometry}
          material={materials["Material.023"]}
          position={[-6.18, 7.65, -3.91]}
          rotation={[2.77, 0.09, 2.52]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["6"].geometry}
          material={materials["Material.041"]}
          position={[10.07, -2.97, -1.41]}
          rotation={[-0.99, 0.36, -1.65]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["7"].geometry}
          material={materials["Material.025"]}
          position={[-9.89, -3.58, -1.2]}
          rotation={[2.92, 0.21, 1.27]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["8"].geometry}
          material={materials["Material.006"]}
          position={[4.09, -3.46, 9.13]}
          rotation={[-1.35, 0.34, -2.75]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["9"].geometry}
          material={materials["Material.039"]}
          position={[6.24, -4.04, -7.55]}
          rotation={[1.77, -0.69, -2.3]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Border.geometry}
          material={nodes.Border.material}
          position={[5.79, 1.73, 8.96]}
          rotation={[1.06, -0.11, -0.62]}
          scale={[214.51, 214.51, 214.51]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Border001.geometry}
          material={nodes.Border001.material}
          position={[9.53, 5.08, -0.16]}
          rotation={[-2.29, -0.18, -1.89]}
          scale={[214.51, 214.51, 214.51]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Border002.geometry}
          material={nodes.Border002.material}
          position={[9.52, -5.11, 0.18]}
          rotation={[0.85, -0.18, -1.89]}
          scale={[214.51, 214.51, 214.51]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Border003.geometry}
          material={nodes.Border003.material}
          position={[5.8, -1.79, -8.92]}
          rotation={[-2.08, -0.11, -0.62]}
          scale={[214.51, 214.51, 214.51]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Border004.geometry}
          material={nodes.Border004.material}
          position={[0.09, -9.41, -5.33]}
          rotation={[0.72, 0, -3.14]}
          scale={[214.51, 214.51, 214.51]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Border005.geometry}
          material={nodes.Border005.material}
          position={[-5.95, -1.71, -8.84]}
          rotation={[-2.08, 0.11, 0.62]}
          scale={[214.51, 214.51, 214.51]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Border006.geometry}
          material={nodes.Border006.material}
          position={[-9.47, -5.21, 0.04]}
          rotation={[0.85, 0.18, 1.89]}
          scale={[214.51, 214.51, 214.51]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Border007.geometry}
          material={nodes.Border007.material}
          position={[-9.47, 5.2, 0.01]}
          rotation={[-2.29, 0.18, 1.89]}
          scale={[214.51, 214.51, 214.51]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Border008.geometry}
          material={nodes.Border008.material}
          position={[-5.95, 1.67, 8.85]}
          rotation={[1.06, 0.11, 0.62]}
          scale={[214.51, 214.51, 214.51]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Border009.geometry}
          material={nodes.Border009.material}
          position={[0.07, 9.38, 5.36]}
          rotation={[-2.42, 0, 3.14]}
          scale={[214.51, 214.51, 214.51]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cerberus.geometry}
          material={materials["SVGMat.005"]}
          position={[-6.3, -4.47, -7.3]}
          rotation={[1.05, -0.13, -0.62]}
          scale={[-81.57, -81.57, -81.57]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Lightning.geometry}
          material={materials["SVGMat.003"]}
          position={[6.24, 4.73, 7.24]}
          rotation={[1.07, -0.1, -0.62]}
          scale={[18.38, 18.38, 18.38]}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload("/Greek Gold.glb");
