/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";

type GLTFResult = GLTF & {
  nodes: {
    d20001: THREE.Mesh;
    ["1001"]: THREE.Mesh;
    ["10001"]: THREE.Mesh;
    ["11001"]: THREE.Mesh;
    ["12001"]: THREE.Mesh;
    ["13001"]: THREE.Mesh;
    ["14001"]: THREE.Mesh;
    ["15001"]: THREE.Mesh;
    ["16001"]: THREE.Mesh;
    ["17001"]: THREE.Mesh;
    ["18001"]: THREE.Mesh;
    ["19001"]: THREE.Mesh;
    ["2001"]: THREE.Mesh;
    ["20001"]: THREE.Mesh;
    ["3001"]: THREE.Mesh;
    ["4001"]: THREE.Mesh;
    ["5001"]: THREE.Mesh;
    ["6001"]: THREE.Mesh;
    ["7001"]: THREE.Mesh;
    ["8001"]: THREE.Mesh;
    ["9001"]: THREE.Mesh;
  };
  materials: {
    ["Material.002"]: THREE.MeshStandardMaterial;
    ["Material.081"]: THREE.MeshStandardMaterial;
  };
};

export default function Model(props: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>();
  const { nodes, materials } = useGLTF(
    "/Soft Edge Opaque Orange.glb"
  ) as GLTFResult;
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.d20001.geometry}
        material={materials["Material.002"]}
        position={[2.63, 0.06, -1.85]}
        rotation={[0.68, 0, 0]}
        scale={[0.11, 0.11, 0.11]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1001"].geometry}
          material={nodes["1001"].material}
          position={[-6.34, -3.15, -7.88]}
          rotation={[-2.05, 0.06, 0.62]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["10001"].geometry}
          material={nodes["10001"].material}
          position={[-3.52, -4.54, 8.96]}
          rotation={[0.6, 1.2, 1.34]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["11001"].geometry}
          material={nodes["11001"].material}
          position={[3.63, 2.28, -9.76]}
          rotation={[-2.98, -1.2, -1.76]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["12001"].geometry}
          material={nodes["12001"].material}
          position={[-5.34, 3.06, 8.69]}
          rotation={[-1.34, 0.71, 2.27]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["13001"].geometry}
          material={nodes["13001"].material}
          position={[-2.6, 2.95, -9.93]}
          rotation={[1.74, -0.46, 2.73]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["14001"].geometry}
          material={nodes["14001"].material}
          position={[9.49, 4.65, 1.28]}
          rotation={[-0.21, -0.2, -1.26]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["15001"].geometry}
          material={nodes["15001"].material}
          position={[-9.92, 3.77, 0.38]}
          rotation={[1.96, -0.36, 1.58]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["16001"].geometry}
          material={nodes["16001"].material}
          position={[7.34, -6.75, 3.92]}
          rotation={[-0.36, -0.1, -2.52]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["17001"].geometry}
          material={nodes["17001"].material}
          position={[-6.79, -6.95, 4.41]}
          rotation={[-2.54, -0.88, 1.13]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["18001"].geometry}
          material={nodes["18001"].material}
          position={[-1.13, 10.58, -0.03]}
          rotation={[-0.01, -0.01, 0]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["19001"].geometry}
          material={nodes["19001"].material}
          position={[-0.5, -8.05, -6.87]}
          rotation={[-2.42, -1, 0]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2001"].geometry}
          material={nodes["2001"].material}
          position={[0.09, 7.45, 7.43]}
          rotation={[0.72, 1.1, 0]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["20001"].geometry}
          material={nodes["20001"].material}
          position={[5.46, 3.71, 8.31]}
          rotation={[1.05, -0.12, -0.62]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3001"].geometry}
          material={nodes["3001"].material}
          position={[-0.44, -10.58, -0.28]}
          rotation={[3.13, -0.04, 0]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["4001"].geometry}
          material={nodes["4001"].material}
          position={[6.25, 7.86, -3.35]}
          rotation={[0.53, 0.86, -1.08]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5001"].geometry}
          material={nodes["5001"].material}
          position={[-6.18, 7.65, -3.91]}
          rotation={[2.77, 0.09, 2.52]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["6001"].geometry}
          material={nodes["6001"].material}
          position={[10.06, -2.86, -1.83]}
          rotation={[-1.24, 0.36, -1.56]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["7001"].geometry}
          material={nodes["7001"].material}
          position={[-9.99, -3.15, -1.55]}
          rotation={[2.92, 0.21, 1.27]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["8001"].geometry}
          material={nodes["8001"].material}
          position={[4.52, -3.27, 9.03]}
          rotation={[-1.42, 0.49, -2.73]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["9001"].geometry}
          material={nodes["9001"].material}
          position={[5.97, -4.03, -7.77]}
          rotation={[1.77, -0.69, -2.3]}
          scale={[6.35, 6.35, 6.35]}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload("/Soft Edge Opaque Orange.glb");
