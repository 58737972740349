/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";

type GLTFResult = GLTF & {
  nodes: {
    d20002: THREE.Mesh;
    ["1002"]: THREE.Mesh;
    ["10002"]: THREE.Mesh;
    ["11002"]: THREE.Mesh;
    ["12002"]: THREE.Mesh;
    ["13002"]: THREE.Mesh;
    ["14002"]: THREE.Mesh;
    ["15002"]: THREE.Mesh;
    ["16002"]: THREE.Mesh;
    ["17002"]: THREE.Mesh;
    ["18002"]: THREE.Mesh;
    ["19002"]: THREE.Mesh;
    ["2002"]: THREE.Mesh;
    ["20002"]: THREE.Mesh;
    ["3002"]: THREE.Mesh;
    ["4002"]: THREE.Mesh;
    ["5002"]: THREE.Mesh;
    ["6002"]: THREE.Mesh;
    ["7002"]: THREE.Mesh;
    ["8002"]: THREE.Mesh;
    ["9002"]: THREE.Mesh;
  };
  materials: {
    ["Material.082"]: THREE.MeshStandardMaterial;
    ["Material.121"]: THREE.MeshStandardMaterial;
  };
};

export default function Model(props: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>();
  const { nodes, materials } = useGLTF("/Metal Orange.glb") as GLTFResult;
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.d20002.geometry}
        material={materials["Material.082"]}
        position={[-2.68, 0, 1.88]}
        rotation={[0.68, 0, 0]}
        scale={[0.11, 0.11, 0.11]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1002"].geometry}
          material={nodes["1002"].material}
          position={[-6.34, -3.15, -7.88]}
          rotation={[-2.05, 0.06, 0.62]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["10002"].geometry}
          material={nodes["10002"].material}
          position={[-3.26, -4.19, 9.19]}
          rotation={[0.6, 1.2, 1.34]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["11002"].geometry}
          material={nodes["11002"].material}
          position={[3.86, 2.95, -9.42]}
          rotation={[-2.98, -1.2, -1.76]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["12002"].geometry}
          material={nodes["12002"].material}
          position={[-5.07, 3.13, 8.88]}
          rotation={[-1.34, 0.71, 2.27]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["13002"].geometry}
          material={nodes["13002"].material}
          position={[-3.71, 3.32, -9.34]}
          rotation={[1.76, -0.4, 2.74]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["14002"].geometry}
          material={nodes["14002"].material}
          position={[9.58, 4.26, 1.64]}
          rotation={[-0.21, -0.2, -1.26]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["15002"].geometry}
          material={nodes["15002"].material}
          position={[-10.06, 3.39, 0.42]}
          rotation={[1.96, -0.36, 1.58]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["16002"].geometry}
          material={nodes["16002"].material}
          position={[7.02, -6.85, 4.24]}
          rotation={[-0.36, -0.1, -2.52]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["17002"].geometry}
          material={nodes["17002"].material}
          position={[-6.72, -7.1, 4.2]}
          rotation={[-2.54, -0.88, 1.13]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["18002"].geometry}
          material={nodes["18002"].material}
          position={[-0.5, 10.59, 0.8]}
          rotation={[-0.01, -0.01, 0]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["19002"].geometry}
          material={nodes["19002"].material}
          position={[-1.12, -8.03, -6.89]}
          rotation={[-2.42, -1, 0]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2002"].geometry}
          material={nodes["2002"].material}
          position={[0.07, 7.34, 7.68]}
          rotation={[0.72, 1.1, 0]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["20002"].geometry}
          material={nodes["20002"].material}
          position={[4.97, 3.32, 8.87]}
          rotation={[1.05, -0.12, -0.62]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3002"].geometry}
          material={nodes["3002"].material}
          position={[-0.04, -10.58, -0.37]}
          rotation={[3.13, -0.14, 0]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["4002"].geometry}
          material={nodes["4002"].material}
          position={[6.69, 7.84, -2.64]}
          rotation={[0.53, 0.86, -1.08]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5002"].geometry}
          material={nodes["5002"].material}
          position={[-6.18, 7.65, -3.91]}
          rotation={[2.77, 0.09, 2.52]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["6002"].geometry}
          material={nodes["6002"].material}
          position={[10.09, -2.83, -1.66]}
          rotation={[-1.24, 0.36, -1.56]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["7002"].geometry}
          material={nodes["7002"].material}
          position={[-9.9, -3.43, -1.47]}
          rotation={[2.92, 0.21, 1.27]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["8002"].geometry}
          material={nodes["8002"].material}
          position={[4.09, -3.46, 9.13]}
          rotation={[-1.35, 0.34, -2.75]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["9002"].geometry}
          material={nodes["9002"].material}
          position={[6.06, -4.23, -7.6]}
          rotation={[1.77, -0.69, -2.3]}
          scale={[6.35, 6.35, 6.35]}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload("/Metal Orange.glb");
