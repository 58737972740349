import React from "react";

function Nav() {
  return (
    <div className="bg-gray-500 flex justify-center items-center h-auto">
      <div className="p-2 flex items-center">
        <img
          className="block lg:block h-16 w-auto"
          src="https://cdn.discordapp.com/attachments/693172476674310164/812695719231619072/Dice_Sculptor_Image.png"
          alt="Workflow"
        />
        <img
          className="block lg:block h-10 w-auto"
          src="https://cdn.discordapp.com/attachments/693172476674310164/812695735987470346/Dice_Sculptor_Writing.png"
          alt="Workflow"
        />
      </div>
    </div>
  );
}

export default Nav;
