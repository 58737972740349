// import React, { useState } from "react";
// import "./App.css";
// import Dice from "./Components/Dice";
// import DiceControls from "./Components/DiceControls";
import Nav from "./Components/Nav";
import Kickstarter from "./images/kickstarter.png";
// function App() {
//   const [material, setSelectedMaterial] = useState("plastic");
//   const [color, setColor] = useState(parseInt("0xEC4899"));
//   const [isTransparent, setTransparency] = useState(false);
//   return (
//     <div style={{ height: `calc(100vh - 80px)` }}>
//       <Nav />
//       <DiceControls
//         material={material}
//         setSelectedMaterial={setSelectedMaterial}
//         color={color}
//         setColor={setColor}
//         isTransparent={isTransparent}
//         setTransparency={setTransparency}
//       />
//       <Dice material={material} color={color} isTransparent={isTransparent} />
//     </div>
//   );
// }

// export default App;

import React, { Suspense, useRef } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Stage } from "@react-three/drei";
import Cleric from "./Model/Cleric";
import Gold from "./Model/Gold";
import Orange from "./Model/Orange";
import SoftOrange from "./Model/SoftOrange";

const ModelArray = [Cleric, Gold, Orange, SoftOrange];

const Model = ModelArray[Math.floor(Math.random() * ModelArray.length)];

export default function Viewer() {
  const ref = useRef<any>();
  console.log(window.innerWidth);
  const fov = window.innerWidth < 500 ? 50 : 40;
  return (
    <div className="h-screen">
      <Nav />
      <div
        className="py-4 justify-center items-center md:flex"
        style={{ backgroundColor: "#fb7f05" }}
      >
        <h1 className="lg:text-3xl font-bold text-lg px-2">
          Your one stop shop for Custom Dice -&nbsp;
          <a
            className="underline"
            target="blank"
            href="https://www.kickstarter.com/projects/946811365/968721438?ref=e65d8i&token=e929a2a3"
          >
            <span>
              Pledge now!{" "}
              <img
                alt="kickstarter"
                className="pl-2 h-8 inline"
                src={Kickstarter}
              />
            </span>
          </a>
        </h1>
      </div>
      <div className="h-3/5 md:h-5/6 bg-black">
        <Canvas shadows camera={{ fov }}>
          <Suspense fallback={null}>
            <Stage
              controls={ref}
              preset="rembrandt"
              intensity={1}
              environment="city"
            >
              <Model />
            </Stage>
          </Suspense>
          <OrbitControls ref={ref} autoRotate />
        </Canvas>
        <div className="bg-gray-500 flex justify-center items-center fixed bottom-0 w-full">
          <div className="p-4 flex w-full justify-center items-center">
            <form
              name="contact"
              method="post"
              className="flex justify-center items-center flex-col md:flex-row"
            >
              <div className="flex justify-center items-center my-2 md:my-0">
                <label
                  className="text-xl font-medium text-white px-2"
                  htmlFor="name"
                >
                  Name:
                </label>
                <input
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-2 pr-12 sm:text-xl border-gray-300 rounded-md py-2"
                  type="text"
                  name="name"
                  placeholder="Droopy Boblin"
                />
              </div>
              <div className="flex justify-center items-center my-2 md:my-0">
                <label
                  htmlFor="email"
                  className="text-xl font-medium text-white px-2"
                >
                  Email:
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="droop@dicesculptor.com"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-2 pr-12 sm:text-xl border-gray-300 rounded-md py-2"
                />
              </div>
              <input type="hidden" name="form-name" value="contact" />
              <button
                className="md:mx-4 text-xl px-8 py-2 text-white rounded-md my-2 md:my-0 w-full md:w-auto font-bold"
                style={{ backgroundColor: "#fb7f05" }}
                type="submit"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
