/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";

type GLTFResult = GLTF & {
  nodes: {
    d20: THREE.Mesh;
    ["1_(Engrave)"]: THREE.Mesh;
    ["10_(Engrave)"]: THREE.Mesh;
    ["11_(Engrave)"]: THREE.Mesh;
    ["12_(Engrave)"]: THREE.Mesh;
    ["13_(Engrave)"]: THREE.Mesh;
    ["14_(Engrave)"]: THREE.Mesh;
    ["15_(Engrave)"]: THREE.Mesh;
    ["16_(Engrave)"]: THREE.Mesh;
    ["17_(Engrave)"]: THREE.Mesh;
    ["18_(Engrave)"]: THREE.Mesh;
    ["19_(Engrave)"]: THREE.Mesh;
    ["2_(Engrave)"]: THREE.Mesh;
    ["3_(Engrave)"]: THREE.Mesh;
    ["4_(Engrave)"]: THREE.Mesh;
    ["5_(Engrave)"]: THREE.Mesh;
    ["6_(Engrave)"]: THREE.Mesh;
    ["7_(Engrave)"]: THREE.Mesh;
    ["8_(Engrave)"]: THREE.Mesh;
    ["9_(Engrave)"]: THREE.Mesh;
    Cleric: THREE.Mesh;
  };
  materials: {
    ["Material.008"]: THREE.MeshStandardMaterial;
    ["Material.030"]: THREE.MeshStandardMaterial;
  };
};

// type ActionName = "D20Action.002";
// type GLTFActions = Record<ActionName, THREE.AnimationAction>;

export default function Model(props: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>();
  const { nodes, materials } = useGLTF("/Cleric.gltf") as GLTFResult;
  // const { actions } = useAnimations<GLTFActions>(animations, group);
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.d20.geometry}
        material={materials["Material.008"]}
        rotation={[0.68, 0, 0]}
        scale={[0.11, 0.11, 0.11]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1_(Engrave)"].geometry}
          material={nodes["1_(Engrave)"].material}
          position={[-5.72, -3.78, -8.07]}
          rotation={[-2.05, 0.06, 0.62]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["10_(Engrave)"].geometry}
          material={nodes["10_(Engrave)"].material}
          position={[-3.03, -3.45, 9.57]}
          rotation={[0.5, 1.2, 1.44]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["11_(Engrave)"].geometry}
          material={nodes["11_(Engrave)"].material}
          position={[3.18, 3.51, -9.48]}
          rotation={[-2.82, -1.21, -1.6]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["12_(Engrave)"].geometry}
          material={nodes["12_(Engrave)"].material}
          position={[-6.04, 3.05, 8.15]}
          rotation={[1.04, 0.15, 0.62]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["13_(Engrave)"].geometry}
          material={nodes["13_(Engrave)"].material}
          position={[-4.25, 2.86, -9.29]}
          rotation={[1.76, -0.4, 2.74]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["14_(Engrave)"].geometry}
          material={nodes["14_(Engrave)"].material}
          position={[9.85, 3.5, 1.69]}
          rotation={[-0.21, -0.2, -1.26]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["15_(Engrave)"].geometry}
          material={nodes["15_(Engrave)"].material}
          position={[-10.04, 3.17, 1.13]}
          rotation={[1.96, -0.36, 1.58]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["16_(Engrave)"].geometry}
          material={nodes["16_(Engrave)"].material}
          position={[5.97, -7.54, 4.53]}
          rotation={[-0.37, -0.08, -2.52]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["17_(Engrave)"].geometry}
          material={nodes["17_(Engrave)"].material}
          position={[-6.57, -7.72, 3.11]}
          rotation={[-2.57, -0.87, 1.11]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["18_(Engrave)"].geometry}
          material={nodes["18_(Engrave)"].material}
          position={[0.03, 10.59, 0.97]}
          rotation={[-0.01, -0.07, 0]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["19_(Engrave)"].geometry}
          material={nodes["19_(Engrave)"].material}
          position={[-0.32, -7.32, -7.7]}
          rotation={[-2.42, -1, 0]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2_(Engrave)"].geometry}
          material={nodes["2_(Engrave)"].material}
          position={[1.28, 6.64, 8.48]}
          rotation={[0.72, 0.99, 0]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3_(Engrave)"].geometry}
          material={nodes["3_(Engrave)"].material}
          position={[-0.02, -10.59, -0.6]}
          rotation={[3.13, 0.09, 0]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["4_(Engrave)"].geometry}
          material={nodes["4_(Engrave)"].material}
          position={[6.8, 7.76, -2.63]}
          rotation={[0.53, 0.86, -1.08]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5_(Engrave)"].geometry}
          material={nodes["5_(Engrave)"].material}
          position={[-6.12, 7.58, -4.18]}
          rotation={[2.77, 0.09, 2.52]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["6_(Engrave)"].geometry}
          material={nodes["6_(Engrave)"].material}
          position={[10.11, -2.94, -1.2]}
          rotation={[-0.97, 0.35, -1.66]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["7_(Engrave)"].geometry}
          material={nodes["7_(Engrave)"].material}
          position={[-9.79, -3.54, -2.05]}
          rotation={[3.07, 0.16, 1.24]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["8_(Engrave)"].geometry}
          material={nodes["8_(Engrave)"].material}
          position={[4.08, -3.03, 9.3]}
          rotation={[-1.43, 0.52, -2.72]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["9_(Engrave)"].geometry}
          material={nodes["9_(Engrave)"].material}
          position={[5.93, -3.93, -7.84]}
          rotation={[1.66, -0.61, -2.36]}
          scale={[6.35, 6.35, 6.35]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cleric.geometry}
          material={nodes.Cleric.material}
          position={[6.31, 5.23, 6.95]}
          rotation={[1.07, -0.11, -0.62]}
          scale={[217.94, 217.94, 217.94]}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload("/Cleric.gltf");
